@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Kaushan+Script&family=Passions+Conflict&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

h1 {
  /* font-family: 'Pacifico', cursive; */
  font-family: 'Josefin Sans', sans-serif;
  color: #41230E;

}

.sub-title {
  font-family: monospace;
  color: #885D30;
  opacity: 0.7;

}

.headName {
  font-size: xx-large !important;
  letter-spacing: 0.4em;
}

p {
  color: #353B2B;
  font-family: 'Raleway', sans-serif;
}

li {
  font-family: 'Raleway', sans-serif;
  color: #353B2B;
}

span {
  font-family: 'Raleway', sans-serif;
}

.textDesign {
  /* position: absolute;
    top: 50%;
    left: 50%; */
  transform: translate(-50%, -50%);
  font-size: 95px !important;
  letter-spacing: 0.1em;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  text-shadow: 8px 8px #ff1f8f, 20px 20px #000000;
}

.light-bg {
  background-color: #F1EEE4;
}

.team .swiper:hover .swiper-button-next,
.team .swiper:hover .swiper-button-prev {
  background-color: #dddbdb;
  display: flex;
  height: 110%;
  top: 0;
  color: #ffffff;
  padding: 20px;
}

.team .swiper-button-next,
.team .swiper-button-prev {
  display: none;
}

.team .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 1px;
  left: auto;
}

.team .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 1px;
  right: auto;
}


.testimonials .swiper-button-next,
.testimonials .swiper-button-prev {
  height: 10%;
  top: 50%;
  border-radius: 50%;
  display: flex;
  color: white;
  background-color: #F1AE55;
  padding: 20px;
}

@media (max-width: 575px) {

  .testimonials .swiper-button-next,
  .testimonials .swiper-button-prev {
    height: 0 !important;
  }
}

.testimonials .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 1px;
  left: auto;
}

.testimonials .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 1px;
  right: auto;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: large;
  color: aliceblue;
}

/***************Admin**************************/
/****************Events****************/
.eventForm .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px 0 32px 0;
  border-radius: 4px;
}

.eventForm .info-box i {
  font-size: 32px;
  color: #e03a3c;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f8d4d5;
}

.eventForm .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.eventForm .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.eventForm .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  border-radius: 4px;
}

.eventForm .php-email-form .dropArea {
  display: grid;
  place-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
}

.eventForm .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.eventForm .php-email-form .error-message br+br {
  margin-top: 25px;
}

.eventForm .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.eventForm .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.eventForm .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.eventForm .php-email-form .form-group {
  margin-bottom: 25px;
}

.eventForm .php-email-form input,
.eventForm .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.eventForm .php-email-form input:focus,
.eventForm .php-email-form textarea:focus {
  border-color: #111111;
}

.eventForm .php-email-form input {
  padding: 10px 15px;
}

.eventForm .php-email-form textarea {
  padding: 12px 15px;
}

.eventForm .php-email-form button[type=submit] {
  background: #e03a3c;
  border: 0;
  padding: 10px 32px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.eventForm .php-email-form button[type=submit]:hover {
  background: #e35052;
}

.eventForm .php-email-form input[type=date],
input[type=time] {
  border: 1px solid #dbdbdb;
  width: 100%;
}

.eventForm .php-email-form label {
  font-weight: 600;
}

/****************BlogPanel*********************/
.blogPanel select {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}

.blogPanel label {
  font-weight: 600;
}

.blogPanel .dropArea {
  display: grid;
  place-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
}

.loading {
  width: 40px;
  height: auto;
}

/****************Swiper Pagination**********************/
.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}

.swiper-pagination-bullet {
  border: 2px solid #ffffff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: transparent;
}

/* .swiper-wrapper {
  height: 70vh !important;
} */

/************************rent***************************/
.rentBtn {
  padding: 12px;
  width: 230px;
  transition: 0.3s;
  border-radius: 0 !important;
  cursor: pointer;
  color: #885D30 !important;
  border: 1px solid #885D30 !important;
}

.rentBtn {
  font-size: 18px;
  font-weight: 600;
  font-family: monospace;
  text-transform: uppercase;
  margin-right: 10px;
}


.rentBtn.active {
  background: #885D30 !important;
  color: #fff !important;
  border-color: #885D30 !important;
}

.rentBtn.active:hover {
  background: transparent !important;
  color: #885D30 !important;
  border-color: #885D30 !important;
}

.rentBtn:hover {
  background: #885D30;
  color: #fff !important;
  border-color: #885D30;
}

@media (max-width: 768px) {
  .rentBtn {
    padding: 15px;
    line-height: 1;
  }
}


@media (max-width: 575px) {
  .rent .nav-link {
    padding: 15px;
  }
}

@media (max-width: 575px) {
  .rent .tab-content p {
    padding: 0 !important;
  }
}

@media (max-width: 575px) {
  .rent .rentBtn {
    width: 100% !important;
  }

}

@media (max-width: 575px) {
  .rent .rentRow {
    padding-left: 35px;
  }

}

.rent .tab-content {
  margin-top: 30px;
}

.rent .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}

.rent .tab-pane ul {
  list-style: none;
  padding: 0;
}

.rent .tab-pane ul li {
  padding-bottom: 10px;
}

.rent .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #e03a3c;
}

.rent .tab-pane p:last-child {
  margin-bottom: 0;
}

.rent .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  transition: 0.5s;
  margin-top: 30px;
  /* color: #F1AE55; */
  background: transparent;
  border-bottom: 1px solid #F1AE55;
  position: relative;
}

.rent .border1 {
  border-bottom: 1px solid;
  border-right: 1px solid;
}

.rent .border2 {
  border-bottom: 1px solid;

}

/* .rent .border2{
  border-bottom: 1px solid;
  -ms-transform: rotate(28deg);
  -ms-transform-origin: 50% 50%; 
  -webkit-transform: rotate(28deg); 
  -webkit-transform-origin: 50% 50%; 
  transform: rotate(28deg);
} */

.rent .border3 {
  border-right: 1px solid;
}

/* ****************** */

.three {
  width: 100%;
  height: 50%;
  /* background: linear-gradient(to bottom right, #fff calc(50% - 3px), #aaa, #fff calc(50% + 3px) ) */
  /* background:linear-gradient(135deg,#ffffff 49%,#444 50%,#ffffff 51%); */
  background: linear-gradient(135deg, #ffffff00 49%, #444 50%, #ffffff03 51%);

}


/***************image responsive***********************/
@media (max-width: 770px) {
  .underlineImage {
    width: 100%;
  }

  /* .three{
    display: none;
  } */
}

.member .tab-pane ul li img {
  width: 15px;
}

/**********table************/
.table th,
.table td {
  padding-left: 30px;
}

/*******************view events*************************/
#singleEvent ul {
  list-style-type: none;
}

#singleEvent .viewEvent {
  border: 5px solid aliceblue;
  border-radius: 30px;
  box-shadow: 0px 0px 20px aliceblue;
}

#singleEvent p a i {
  font-size: larger;
  padding: 4px;
  border-radius: 30px;
}

#singleEvent .fb i {
  color: white;
  background-color: rgb(91, 91, 252);
}

#singleEvent .insta i {
  background-color: rgb(248, 85, 85);
  color: white;
}

#singleEvent .twt i {
  background-color: rgb(7, 167, 241);
  color: white;
}

#singleEvent .g-plus i {
  color: white;
  background-color: rgb(16, 198, 211);
}

#singleEvent .link i {
  color: white;
  background-color: rgb(52, 93, 207);
}

/******************About**************************/

#tabs .tab-content .tab-pane .abtCircle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: -4%;
  margin-left: -19%;
  background-color: #b1ec76;
}

#tabs .tab-content .tab-pane .abtCircle1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: -4%;
  margin-left: -25%;
  background-color: rgb(204 80 120);
}

#tabs .tab-content .tab-pane .abtCircle2 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: -4%;
  margin-left: -22%;
  background-color: rgb(116, 198, 245);
}

#tabs .tab-content .tab-pane h5 {
  position: absolute;
  font-size: 16px;
  font-family: OpenSans-SemiBold, sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 5.14px;
  color: #000;
}

@media (max-width: 575px) {
  #tabs .tab-content .tab-pane .abtCircle {
    margin-left: -88%;
  }

  #tabs .tab-content .tab-pane .abtCircle1 {
    margin-left: -155%;
  }

  #tabs .tab-content .tab-pane .abtCircle2 {
    margin-left: -88%;
  }

  #tabs .tab-content .tab-pane h5 {
    margin-top: 7%;
  }

  #tabs .tab-content .tab-pane img {
    padding: 0 !important;
    width: 100%;
  }

  #tabs .tab-content .tab-pane .col-md-6 {
    padding-right: 0 !important;
  }


}

.ReadBtn {
  color: #F1AE55;
  font-weight: 600;
}

/****************about team*******************/
.row .team-card {
  flex-basis: calc(25% - 20px);
  max-width: calc(25% - 20px);
  perspective: 1000px;
  background-color: transparent;
  margin-bottom: 30px;
}

.row .team-card .team-card-wrapper {
  position: relative;
  transition: all 0.3s linear;
  transform-style: preserve-3d;

}

.team-card.rotete .team-card-front {
  /* background-color: #f1f6ff; */
  border-radius: 15px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  visibility: visible;
  backface-visibility: hidden;

}

.team-card-front {
  background-color: #dfdfdf;
  border-radius: 15px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.img {
  height: 360px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.team-card-front .img {
  height: 300px
}

.team-card.rotete {
  outline: 2px dashed #d1baa6;
  outline-offset: -14px;
  overflow: hidden;
}

.img.mem {
  justify-content: unset;
  padding: 22px;
}

.team-card-front img {
  width: 100%;
  /* height: 350px; */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  object-fit: cover;
}

.img.mem .team-about {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team-about p.py-3 {
  font-size: 15px;
  height: 148px;
  line-height: 22px;
  font-weight: 400;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
  overflow-wrap: anywhere;
}

.team-card-front .team-details {
  padding: 19px 20px 19px 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 999;
}

.team-card-front .team-details::before {
  content: '';
  background-color: #ddddddd9;
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  left: 0;
  z-index: 777;
  border-radius: 12px 50px 5px 12px;
  bottom: -9px;
}

.team-card-front .team-details h4,
.team-card-front .team-details h6 {
  position: relative;
  z-index: 99999;
  color: #e78400 !important;
}

.team-card-front .team-details button {
  position: relative;
  z-index: 999;
  background: none;
  border: 0;
  color: #000;
  text-decoration: underline;
  text-underline-offset: 6px;
}

.btn-underline {
  position: relative;
  z-index: 999;
  background: none;
  border: 0;
  color: #000;
  text-decoration: underline;
  text-underline-offset: 6px;
}

.team-card-front .team-details h4 {
  font-size: 16px;
  font-family: OpenSans-SemiBold, sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 5.14px;
  color: #000;
}

.team-card-back {
  /* background-color: #f1f6ff; */
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 2;
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

}

.team-card-back .team-about h4 {
  font-size: 24px;
  line-height: 44px;
  color: #f89a0d;
  font-family: 'Pacifico';
  font-weight: 800;
}

.team-card-back .team-about h6 {
  color: #f89a0d;
  font-weight: 600;
  opacity: .7;
}

.team-card-back .team-about p {
  font-size: 15px;
  height: 210px;
  line-height: 22px;
  font-weight: 400;
  overflow-y: scroll;
  scroll-behavior: smooth;
  position: relative;
}

.team-card.rotete:hover .team-card-wrapper {
  transform: rotateY(180deg);
}



@media (max-width:1024px) {
  .row .team-card {
    flex-basis: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
}


@media (max-width:767px) {
  .row .team-card {
    flex-basis: calc(100%);
    max-width: calc(100%);
  }

  .team-section-heading {
    text-align: center;
  }

  .team-section-heading .seperator {
    margin: 28px auto 24px;
  }

}

.aboutText {
  line-height: 2;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.aboutText p {
  text-align: justify;
}

.aboutText::before {
  content: '';
  position: absolute;
  border-left: 3px solid #F1AE55;
  border-top: 3px solid #F1AE55;
  top: 0;
  left: 0;
  width: 30%;
  height: 30%;
}

.aboutText::after {
  content: '';
  position: absolute;
  border-right: 3px solid #F1AE55;
  border-bottom: 3px solid #F1AE55;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 30%;
}

@media (max-width:575px) {
  .aboutText::after {
    width: 65%;
    height: 15%;
    right: 22px;
  }

}

@media (max-width:575px) {
  .aboutText::before {
    width: 65%;
    height: 15%;
    left: 22px;
  }

}

/**************************Image preview******************************/
.card {
  width: 400px;
  height: auto;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  background: #fafbff;
}

.card .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card p {
  font-size: 0.9rem;
  font-weight: 600;
  color: #878a9a;
}

.card button {
  outline: 0;
  border: 0;
  -webkit-appearence: none;
  background: #5256ad;
  color: #fff;
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;
  font-weight: 400;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  font-size: 0.8rem;
  padding: 8px 13px;
}

.card button:hover {
  opacity: 0.8;
}

.card button:active {
  transform: translateY(5px);
}

.card .drag-area {
  width: 100%;
  height: 160px;
  border-radius: 5px;
  border: 2px dashed #d5d5e1;
  color: #c8c9dd;
  font-size: 0.9rem;
  font-weight: 500;
  position: relative;
  background: #dfe3f259;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
}

.card .drag-area .visible {
  font-size: 18px;
}

.card .select {
  color: #5256ad;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s;
}

.card .select:hover {
  opacity: 0.6;
}

.card .container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
}

.card .container .image {
  width: calc(26% - 19px);
  margin-right: 15px;
  height: 75px;
  position: relative;
  margin-bottom: 8px;
}

.card .container .image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.card .container .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

/* dragover class will used in drag and drop system */
.card .drag-area.dragover {
  background: rgba(0, 0, 0, 0.4);
}

.card .drag-area.dragover .on-drop {
  display: inline;
  font-size: 28px;
}

.card input,
.card .drag-area .on-drop,
.card .drag-area.dragover .visible {
  display: none;
}

/*********************Clubs****************************/
.tabs .link h5 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  letter-spacing: 2px;
}

.tabs .active h5,
.tabs .active .bi {
  color: #F1AE55 !important;
}

.tabs .tab-content .tab-pane li {
  list-style: none;
}

@media (max-width: 768px) {
  .tabs .link {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .tabs .link {
    padding: 15px;
  }

  .tabs .link {
    font-size: 24px;
  }
}

/*****************************Volunteer***********************************/
#volunteer .php-email-form {
  /* box-shadow: 0 0 30px rgba(214, 215, 216, 0.6); */
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
}

#volunteer .php-email-form .dropArea {
  display: grid;
  place-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
}

#volunteer .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

#volunteer .php-email-form .error-message br+br {
  margin-top: 25px;
}

#volunteer .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

#volunteer .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

#volunteer .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

#volunteer .php-email-form .form-group {
  margin-bottom: 25px;
}

#volunteer .php-email-form input,
#volunteer .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

#volunteer .php-email-form input:focus,
#volunteer .php-email-form textarea:focus {
  border-color: #111111;
}

#volunteer .php-email-form input {
  padding: 10px 15px;
}


#volunteer .php-email-form button[type=submit] {
  background: #F1AE55;
  border: 0;
  padding: 10px 32px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

#volunteer .php-email-form button[type=submit]:hover {
  background: #ffffff;
  color: #F1AE55;
  border: 1px solid #F1AE55;
}

#volunteer .php-email-form label {
  font-size: medium;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/*************************Research****************************/

.rd .rdContent i {
  font-size: 24px;
  color: #F1AE55;
  margin-right: 7px;
}

.rd .rdContent span:hover {
  border: 1px solid #F1AE55;
  color: #F1AE55;
  background-color: #fff;
}

.rd .rdContent span {
  background-color: #F1AE55;
  color: #fff;
}

@media (max-width: 575px) {
  .rd .rdContent span {
    display: grid;
  }
}

.rd .rdMeta ul li a {
  color: #777;
}

.rd {
  padding: 40px 0 20px 0;
}

.rd .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-top: 2px solid #F1AE55;
  border-left: 2px solid #F1AE55;
}

.rd .entry .entry-title {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.rd .entry .entry-title a {
  color: #111111;
  transition: 0.3s;
}

.rd .entry .entry-title a:hover {
  color: #999898;
}

.rd .rdContent p {
  color: #777777;
}


/*******************upcoming events************************/

.review-swiper-button-next {
  background-color: #F1AE55;
  width: 40px;
  color: #fff;
  padding: 163px 10px;
  position: absolute;
  margin-left: 3px;
}

.review-swiper-button-prev {
  width: 40px;
  background-color: #F1AE55;
  color: #fff;
  padding: 163px 10px;
  position: absolute;
  margin-right: 3px;
}

.review-swiper-button-next.swiper-button-disabled,
.review-swiper-button-prev.swiper-button-disabled {
  background-color: rgb(123, 121, 121);
  opacity: 0.2;
  cursor: auto;
  pointer-events: none;
}

/******************************blog section***********************************/
.team .review-swiper-button-next {
  background-color: #F1AE55;
  width: 40px;
  color: #fff;
  padding: 210px 10px;
  position: absolute;
  margin-left: 3px;
}

.team .review-swiper-button-prev {
  width: 40px;
  background-color: #F1AE55;
  color: #fff;
  padding: 210px 10px;
  position: absolute;
  margin-right: 3px;
}

.team .review-swiper-button-next.swiper-button-disabled,
.team .review-swiper-button-prev.swiper-button-disabled {
  background-color: rgb(123, 121, 121);
  opacity: 0.2;
  cursor: auto;
  pointer-events: none;
}


/********************gallery**************************/
.tab-content .tab-pane .wrapper {
  width: 80vw;
  margin: 3em auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 50px;
  grid-auto-flow: dense;
  grid-gap: 5px;
}

.tab-content .tab-pane .wrapper img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.large,
.medium,
.small {
  display: flex;
  height: 100%;
  width: 100%;
  grid-column: auto / span 1;
}

.large {
  grid-row: span 4;
}

.medium {
  grid-row: span 3;
}

.small {
  grid-row: span 2;
}

/***********************dashboard****************************/

.dash .reports {
  border: 1px solid #dbdbdb;
  box-shadow: 0px 0px 10px #dbdbdb;
  padding: 10px;
}

.dash .reports .event {
  border: 1px solid #5cf0a1;
  background-color: #dfffee;
  padding: 30px;
  border-radius: 5px;
  color: #098643;
}

.dash .reports .blogs {
  border: 1px solid rgb(156, 203, 247);
  background-color: aliceblue;
  padding: 30px;
  border-radius: 5px;
  color: #5256ad;
}

.dash .reports .media {
  border: 1px solid #f8a4df;
  background-color: #ffe3f9;
  padding: 30px;
  border-radius: 5px;
  color: #9c036e;
}

.dash .reports .paper {
  border: 1px solid #ecf83f;
  background-color: #f9fdc3;
  padding: 30px;
  border-radius: 5px;
  color: #867d01;
}

.dash .reports i {
  font-size: 20px;
}

.dash .reports .icon i {
  font-size: 40px;
}

.dash .reports .icon {
  padding: 0 20px;
}

.dash .rp {
  background-color: #055a81;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #9ea1df;
  justify-content: space-between;
  padding: 10px 20px;
}

.dash .reports .btnForm a {
  color: #098643;
}

.dash .reports .btnForm1 a {
  color: #5256ad;
}

.dash .reports .btnForm2 a {
  color: #9c036e;
}

.dash .reports .btnForm3 a {
  color: #867d01;
}

.dash .reports .btnForm,
.dash .reports .btnForm1,
.dash .reports .btnForm2,
.dash .reports .btnForm3 {
  padding: 5px 0;
  width: 100%;
}

.MuiBox-root .css-130f8nx {
  margin-left: 10%;
}


/**************************Login Forms*******************************/

.box-form .right .facebook i {
  background-color: #3b5998;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.box-form .right .twitter i {
  background-color: #1DA1F2;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.box-form .right .linkedin i {
  background-color: #2867B2;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.line {
  height: 1px;
  width: 40%;
  background-color: #E0E0E0;
  margin-top: 10px;
}

.or {
  width: 10%;
  font-weight: bold;
}

/***********************Rental**********************/
.circle {
  padding: 10px 4px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.circle button {
  vertical-align: middle;
  width: 25%;
  border: 1px solid #41230E;
  border-radius: 60px;
  font-family: 'Raleway';
  font-weight: 400;
  cursor: pointer;
  margin-right: 5px;
  flex: 1 1 auto;
  margin-bottom: 20px;
  font-size: medium;
  padding: 7px;
  background-color: #F1EEE4;

}


.circle button:hover {
  background-color: #41230E;
  color: #fff
}

.back i {
  font-size: larger;
  cursor: pointer;
}

.dotPage {
  padding: 5px 10px;
  border: 1px solid #885D30;
  border-radius: 50%;
  margin-right: 5px;
}

.activeDot {
  background-color: #885D30 !important;
  color: #ffffff !important;
  padding: 6px 11px;
}

/************************Upcoming Events*******************************/
.evnt .dateCircle {
  border: 1px solid white;
  padding: 4px 10px;
  /* border-radius: 50%; */
  background: #885D30;
  color: white;
}

.home-event {
  /* display: flex;
  align-items: baseline;
  justify-content: space-between; */

  position: absolute;
  bottom: 8px;
  width: 100%;
}

.event-name {
  max-height: 41px;
  overflow: hidden;
  font-size: medium;
  min-height: 30px;
}

/* .evnt{
  margin-top: -15px;
} */


/*************Rental image*****************/
.EvntImage img {
  border: 2px solid #F1AE55;
  border-radius: 50px;
}

.mmberImg {
  height: 565px !important;
}

/**************contact***********************/




/* badge */
.my-badge {
  position: absolute;
  border-bottom: 30px solid #4d9c40;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  height: 0;
  width: 140px;
  text-align: center;
  transform: rotate(-45deg);
  left: -30px;
  top: 25px;
  color: #fff;
}

.my-badge::before {
  content: "";
  border-bottom: 11px solid #2d6824;
  border-left: 11px solid transparent;
  transform: rotate(45deg);
  left: -27px;
  top: 24px;
  position: absolute;
}

.my-badge::after {
  content: "";
  border-bottom: 11px solid #2d6824;
  border-left: 11px solid transparent;
  transform: rotate(45deg);
  left: 76px;
  top: 24px;
  position: absolute;
}

.badge-disabled {
  border-bottom: 30px solid #6d716c !important;
}

.event-portrait {
  height: 500px !important;
}

.gallary-pop {
  height: 80vh;
  object-fit: contain;
}

.gallary-grid {
  height: 300px;
  object-fit: cover;
}

.archives-btn {
  border: 2px solid #885d30;
  margin-bottom: 11px;
  padding: 4px;
}

@media (max-width: 575px) {
  .circle button {
    font-size: 10px;
    margin: 5px;
    color: #41230e;
  }

  .gallary-pop {
    height: fit-content;
    object-fit: contain;
    width: 95vw;
  }
}

@media (min-width: 1000px) and (max-width: 1220px) {
  .team-card-back .team-about p {
    height: 175px;
  }

  .team-card-back .team-about p::-webkit-scrollbar {
    width: 7px;
  }

  .team-card-back .team-about p::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px lightblue;
    border-radius: 10px;
  }

  .team-card-back .team-about p::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

}

.imgsection:hover {
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  /* animation-iteration-count: infinite; */
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}