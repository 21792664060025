/**
* Template Name: Presento - v3.10.0
* Template URL: https://bootstrapmade.com/presento-bootstrap-corporate-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import url('https://fonts.google.com/specimen/Montserrat');
@import url('https://fonts.google.com/specimen/Pacifico');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #41230E;
  text-decoration: none !important;
}

a:hover {
  color: #F1AE55;
  text-decoration: none;
}

a i {
  color: #41230E;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  color: #41230E;
}

.bg-custom-light {
  background-color: #F1EEE4;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #e03a3c;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #e65d5f;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Hide Upper logo
--------------------------------------------------------------*/
.m-logo {
  display: none;
}

@media screen and (max-width: 768px) {
  .upperLogo {
    display: none !important;
  }

  .m-logo {
    display: block;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #885D30;
  transition: all 0.5s;
  z-index: 997;
  /* padding: 10px 0; */
  border-bottom: 1px solid orange;
  width: 100%;
  /* position: fixed;
  top: 0; */
}

.fixed {
  transition: .3s ease-in-out;
  position: fixed;
  top: 0;
}

#header.header-scrolled {
  padding: 12px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #111111;
}

#header .logo a span {
  color: #e03a3c;
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Get Startet Button
--------------------------------------------------------------*/
.get-started-btn {
  margin-left: 30px;
  background: #e03a3c;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}

.get-started-btn:hover {
  background: #111111;
  color: #fff;
}

@media (max-width: 992px) {
  .get-started-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #b9b9b9;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #e03a3c;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  padding: 4px;
  position: relative;
  left: 125px;
}

.mobile-nav-toggle.bi-x {
  color: crimson;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 18px;
  left: 255px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 13px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #111111;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #e03a3c;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #e03a3c;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  background: url("../public/assets/hero-bg.jpg") top center no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 82px;
}

#hero1 {
  width: 100%;
  height: 70vh;
  background: url("../public/assets/bg1.jpg") top center no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 82px;
}

#hero2 {
  width: 100%;
  height: 70vh;
  background: url("../public/assets/tabs-1.jpg") top center no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 82px;
}

#hero:before,
#hero1::before,
#hero2::before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero h1,
#hero1 h1,
#hero2 h1 {
  margin: 0;
  color: #fff;
  position: relative;
  font-size: 30px;
}

#hero h2,
#hero1 h2,
#hero2 h2 {
  color: #fff;
  margin: 10px 0 0 0;
  font-size: 24px;
  position: relative;
}

#hero .btn-get-started,
#hero1 .btn-get-started,
#hero2 .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #F1AE55;
  border: 2px solid #885D30;
  position: relative;
}

#hero .btn-get-started:hover,
#hero1 .btn-get-started:hover,
#hero2 .btn-get-started:hover {
  background: transparent;
  border-color: #fff;
}

@media (max-width: 768px) {
  #hero {
    text-align: center;
    padding-top: 58px;
  }

  #hero h1 {
    font-size: 28px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 500px) {
  #hero {
    height: 120vh;
  }

  .swiper-wrapper #hero1 {
    height: 26vh;
  }

  #hero h1,
  #hero1 h1,
  #hero2 h1 {
    margin: 0;
    color: #fff;
    position: relative;
    font-size: 25px;
  }

  #hero .btn-get-started,
  #hero1 .btn-get-started,
  #hero2 .btn-get-started {
    font-size: 13px;
    padding: 7px 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
  position: relative;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #e03a3c;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

.section-bg {
  padding: 120px 0;
  color: #fff;
}

.section-bg:before {
  content: "";
  background: #1b1b1b;
  position: absolute;
  bottom: 60px;
  top: 60px;
  left: 0;
  right: 0;
  transform: skewY(-3deg);
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #2b2b2b;
  min-height: 40px;
  margin-top: 82px;
  color: #fff;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 500;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol a {
  color: #aaaaaa;
}

.breadcrumbs ol a:hover {
  color: #fff;
  transition: 0.3s;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #e03a3c;
  content: "/";
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e03a3c;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e03a3c;
}

.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .swiper-slide img:hover {
  opacity: 1;
  filter: none;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .container {
  position: relative;
  z-index: 10;
}

.about .content {
  padding: 30px 30px 30px 0;
}

.about .content h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.about .content p {
  margin-bottom: 30px;
}

.about .content .about-btn {
  padding: 8px 30px 9px 30px;
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  border: 2px solid #e03a3c;
}

.about .content .about-btn i {
  font-size: 16px;
  padding-left: 5px;
}

.about .content .about-btn:hover {
  background: #e35052;
  background: #e03a3c;
}

.about .icon-boxes .icon-box {
  margin-top: 30px;
}

.about .icon-boxes .icon-box i {
  font-size: 40px;
  color: #e03a3c;
  margin-bottom: 10px;
}

.about .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.about .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
}

@media (max-width: 1200px) {
  .about .content {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .about {
    text-align: center;
  }
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding-top: 80px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.counts .count-box i {
  position: absolute;
  width: 54px;
  height: 54px;
  top: -27px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #fff;
  color: #e03a3c;
  border-radius: 50px;
  border: 2px solid #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 700;
  color: #111111;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Tabs
--------------------------------------------------------------*/
.tabs .nav-tabs {
  border: 0;
}

.tabs .link {
  padding: 15px;
  transition: 0.3s;
  color: #111111;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabs .link i {
  padding-right: 15px;
  font-size: 48px;
}

.tabs .link h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  letter-spacing: 5px;
}


.tabs .active h4 {
  color: #F1AE55 !important;
  border: none !important;
}


@media (max-width: 768px) {
  .tabs .link {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .tabs .link {
    padding: 15px;
  }

  .tabs .link {
    font-size: 24px;
  }
}

.tabs .tab-content {
  margin-top: 30px;
}

.tabs .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}

.tabs .tab-pane ul {
  list-style: none;
  padding: 0;
}

.tabs .tab-pane ul li {
  padding-bottom: 10px;
}

.tabs .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #e03a3c;
}

.tabs .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
  background: #252525;
  transition: 0.3s;
}

.services .icon-box:hover {
  background: #2b2b2b;
}

.services .icon-box i {
  float: left;
  color: #e03a3c;
  font-size: 40px;
  font-size: 20px;
  padding-right: 10px;
  padding-top: 2px;
  line-height: 0;
}

.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 a {
  color: #fff;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  text-decoration: underline;
}

.services .icon-box .icon-box:hover h4 a {
  color: #e03a3c;
}

.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #e03a3c;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(17, 17, 17, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(17, 17, 17, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #fff;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e03a3c;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e03a3c;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(17, 17, 17, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 30px 15px;
  position: relative;
}

.testimonials .testimonial-item .testimonial-img {
  width: 150px;
  border-radius: 50%;
  border: 5px solid #F1AE55;
  margin: 0 10px 0 0;
}


.testimonials .testimonial-item p {
  margin: 30px auto 15px auto;
  padding: 0 10%;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 2px solid #F1AE55;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #F1AE55 !important;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #2b2b2b;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}

.pricing .box h4 {
  font-size: 42px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul i {
  color: #e03a3c;
  font-size: 18px;
  padding-right: 4px;
}

.pricing .box ul .na {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: line-through;
}

.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .box .btn-buy {
  display: inline-block;
  padding: 10px 40px 12px 40px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.pricing .box .btn-buy:hover {
  border-color: #fff;
}

.pricing .featured {
  background: #e03a3c;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: #e03a3c;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-right: 25px;
  cursor: pointer;
}

.faq .faq-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #e03a3c;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #111111;
  margin: 0 3px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: #e03a3c;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.team .member .social a:hover {
  background: #111111;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #111111;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

.team .member .member-info a:hover {
  color: crimson;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px 0 32px 0;
  border-radius: 4px;
}

.contact .info-box i {
  font-size: 32px;
  color: #e03a3c;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f8d4d5;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  border-radius: 4px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 25px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #111111;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #e03a3c;
  border: 0;
  padding: 10px 32px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #e35052;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #111111;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #e03a3c;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #777777;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li+li {
  padding-left: 10px;
}

.blog .entry .entry-meta i {
  font-size: 12px;
  margin-right: 5px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 11px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  /* display: inline-block; */
  background: #e03a3c;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #e35052;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #111111;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #5e5e5e;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #1e1e1e;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #e03a3c;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li+li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #111111;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(17, 17, 17, 0.5);
  margin-right: 5px;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #e03a3c;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #111111;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #2b2b2b;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #ee9293;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #ee9293;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  padding: 10px 20px;
  border: 0;
  background-color: #F1AE55;
  text-transform: uppercase;
  font-family: monospace;
  font-weight: 600;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #ffffff;
  border: 1px solid #F1AE55;
  color: #F1AE55;
}

.blog .blog-pagination {
  color: #F1AE55;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #111111;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #F1AE55;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #111111;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #F1AE55;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
  border-color: #F1AE55;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #F1AE55;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #111111;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #F1AE55;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #111111;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #e03a3c;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #515151;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #c4c4c4;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #F1AE55;
  background: #F1AE55;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #fff;
  font-size: 14px;
  background: #111111;
}

#footer .footer-top {
  padding: 30px 0 30px 0;
}

#footer .footer-top::after {
  content: "";
  display: block;
  width: 88%;
  height: 1px;
  background: #fff;
  margin-left: 7%;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 26px;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact h3 span {
  color: #e03a3c;
}

#footer .footer-top .footer-contact p {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
  font-family: 'Raleway', sans-serif;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
  color: #F1AE55;
}


#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: white;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul i:hover {
  color: #bbb1b1;
}

#footer .footer-top .footer-links ul li {
  padding: 2px 0;
  display: flex;
  align-items: center;
  font-size: 12px;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #ffffff;
  transition: 0.3s;
  display: inline-block;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #bbb1b1;
}


#footer .credits {
  padding-top: 5px;
  font-size: 13px;
}

#footer .social-links .facebook {
  font-size: 18px;
  display: inline-block;
  background: #0b2c8a;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links .instagram {
  font-size: 18px;
  display: inline-block;
  background: #e03a3c;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #faf5f5;
  color: #2b2929;
  text-decoration: none;
}

/**********************************Rental************************/

.rental .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px 0 32px 0;
  border-radius: 4px;
}

.rental .info-box i {
  font-size: 32px;
  color: #e03a3c;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f8d4d5;
}

.rental .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.rental .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.rental .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  border-radius: 4px;
}

.rental .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.rental .php-email-form .error-message br+br {
  margin-top: 25px;
}

.rental .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.rental .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.rental .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.rental .php-email-form .form-group {
  margin-bottom: 25px;
}

.rental .php-email-form input,
.rental .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.rental .php-email-form input:focus,
.rental .php-email-form textarea:focus {
  border-color: #111111;
}

.rental .second .php-email-form input {
  padding: 10px 15px;
  border: 2px solid #f7ad27;
}

.rental .second .php-email-form label {
  color: #692b02;
}

.rental .php-email-form textarea {
  padding: 12px 15px;
}

.php-email-form button[type=submit] {
  background: #F1AE55;
  border: 0;
  padding: 10px 32px;
  color: #fff;
  transition: 0.4s;
}

select {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

select.deep {
  border: 2px solid #f7ad27;
}

label {
  color: #777;
}

select:focus {
  border: 2px solid #000;
}

input[type=time]:focus {
  border: 2px solid #4e7bf5;
}

.php-email-form button[type=submit]:hover {
  background: #ffffff;
  color: #F1AE55;
  border: 1px solid #F1AE55;
}

.categories .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 30px 15px;
  min-height: 200px;
  position: relative;
  background: #fff;
  border: 1px solid rgba(127, 137, 161, 0.25);
  border-radius: 15px;
}

.categories .testimonial-wrap:hover .testimonial-item {
  border-top: 3px solid #F1AE55;
  box-shadow: 0 30px 30px 0 rgba(127, 137, 161, 0.25);
}

.rental-features h5 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  margin-top: 20px;
}

.rental-features h5::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #F1AE55;
  opacity: 0.6;
  bottom: 0;
  left: calc(50% - 25px);
}

.rental-features p {
  text-align: center;
  font-size: 14px;
  line-height: 26px;

}

.rental-features ul li img {
  width: 20px;
}


.feature-block {
  border-radius: 10px;
  box-shadow: 0 0 30px rgb(214 215 216 / 60%);
  padding: 30px 10px;
  background-color: white;
  margin-right: 0px;
  border-top: 3px solid #F1AE55;
}

.tab-pane ul {
  list-style: none;
  padding: 0;
}

.tab-pane ul li {
  padding-bottom: 10px;
}

.tab-pane ul i {
  font-size: 16px;
  padding-right: 4px;
  color: #F1AE55;
}

.categories .icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  color: #3fbbc0;
  overflow: hidden;
  box-shadow: 0px 0 25px rgb(0 0 0 / 15%);
}

.categories .icon img {
  width: 60px;
  height: 60px;
  font-size: 36px;
  line-height: 0;
}

.categories .icon:hover {
  border: 2px #3fbbc0 inset;
  color: #18d26e;
}

.categories .section-title h4 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}

.categories .section-title h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #F1AE55;
  opacity: 0.6;
  bottom: 0;
  left: calc(50% - 25px);
}

.categories .description {
  line-height: 24px;
  font-size: 14px;
}

.appointment-btn {
  margin-left: 25px;
  background: #F1AE55;
  border-color: #F1AE55;
  color: #fff;
  padding: 15px 40px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 18px;
  display: inline-block;
  border: 1px solid #F1AE55;
  text-transform: uppercase;
  font-family: monospace;
  font-weight: 600;
}

.appointment-btn:hover {
  background: #ffffff;
  color: #F1AE55;
  border: 1px solid #F1AE55;
  border-color: #F1AE55;
}

.facility {
  margin-left: 28%;
}

.rentalImage .img-fluid {
  border: 2px solid #F1AE55;
  border-radius: 0px 100px 100px 100px;
}

/******************Member****************************/
.member .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px 0 32px 0;
  border-radius: 4px;
}

.member .info-box i {
  font-size: 32px;
  color: #e03a3c;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #f8d4d5;
}

.member .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.member .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.member .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  border-radius: 4px;
}

.member .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.member .php-email-form .error-message br+br {
  margin-top: 25px;
}

.member .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.member .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.member .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.member .php-email-form .form-group {
  margin-bottom: 25px;
}

.member .php-email-form input,
.member .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.member .php-email-form input:focus,
.member .php-email-form textarea:focus {
  border-color: #111111;
}

.member .php-email-form input {
  padding: 10px 15px;
}

.member .php-email-form textarea {
  padding: 12px 15px;
}

.member h3 {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  margin-top: 20px;
}

/* .toggle{
  position: fixed;
  right: 0;
} */

.dotted {
  /* text-decoration-; */
  text-decoration-style: dotted;
  text-decoration-line: underline;
}

.memberImage .img-fluid {
  border: 2px solid orange;
  border-radius: 100px 0px 100px 100px;
}

/*************************volunteer****************************/
#volunteer .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: crimson;
  border: 2px solid crimson;
  position: relative;
}

#volunteer .btn-get-started:hover {
  background-color: transparent;
  color: crimson;
}

.z-i-99 {
  z-index: 99;
}



/* for login page  */

.box-form {
  margin: 0 auto;
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex: 1 1 100%;
  align-items: stretch;
  justify-content: center;
  box-shadow: 0px 0px 10px aliceblue;
}

@media (max-width: 980px) {
  .box-form {
    flex-flow: wrap;
    text-align: center;
    align-content: center;
    align-items: center;
  }
}


.box-form .right {
  padding: 20px 40px;
  overflow: hidden;
  width: 100%;
}

@media (max-width: 980px) {
  .box-form .right {
    width: 100%;
  }
}

.box-form .right h5 {
  font-size: 6vmax;
  line-height: 0;
}

.box-form .right p a {
  font-size: 14px;
  color: #B0B3B9;
}

.box-form .right .inputs {
  overflow: hidden;
}

.box-form .right input {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2C3E50;
  font-size: 14px;
  letter-spacing: 1px;
}

.box-form .right input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #304FFE;
  outline-width: 0;
}

.box-form ::placeholder {
  color: #BDBDBD;
  opacity: 1;
  font-weight: 300;
  font-family: 'Raleway';
}

:-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 300
}

::-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 300
}

.box-form label {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Raleway';
  padding-bottom: 5px;
}

.box-form .right .remember-me--forget-password {
  display: flex;
  justify-content: space-between;
}

.box-form .right .remember-me--forget-password p {
  margin-left: 30px;
}

.box-form .right .remember-me--forget-password input {
  margin: 0;
  margin-right: 7px;
  width: auto;
}

.box-form .right button {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  border-radius: 2px;
  width: 100%;
  font-size: 16px;
  padding: 10px 40px;
  border: 0;
  outline: 0;
  background-image: linear-gradient(135deg, #179abb 10%, #3828c6 100%);
}

.right label {
  display: block;
  position: relative;
}

.right label:hover::before .text-checkbox {
  background: #70F570;
}

.right label span.text-checkbox {
  display: inline-block;
  height: auto;
  position: relative;
  cursor: pointer;
  transition: all 0.2s linear;
}

.border-right {
  border-right: solid 1px;
}

.border-top {
  border-top: solid 1px !important;
}

/* .tinny-font{
  font-size: xx-small !important;
} */

.about-sections>.row:nth-child(2) {
  padding: 32px 0;
}

@media (max-width: 500px) {
  .tabs .active h4 {
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .tabs.about .link {
    padding: 10px 0;
  }

  .about-sections {
    padding: 0 28px 0 17px;
  }

  /* .about-sections  > .row .col-md-6:nth-child(2) h1 {
    padding: 22px 0 0;
  } */
  .about-sections .row.sec:nth-child(even) {
    flex-direction: column-reverse !important;
  }

  .sec-heading {
    padding: 20px 0 0;
  }

  section {
    padding: 40px 0
  }

  .tabs.about .link.active h4 {
    color: white !important;
    border: none !important;
    background: #F1AE55 !important;
    width: 100%;
    text-align: center;
    justify-content: center;
    padding: 6px 0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
  }

  .tabs.about .link h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    letter-spacing: 6px;
  }
}

.grayscale {
  filter: grayscale(100%);
}

.bg-1 {
  /* background-image: url('../public/assets/img/bg-1.png'); */
  position: absolute;
  top: 0;
  left: 85%;
  width: 30%;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-1-left {
  /* background-image: url('../public/assets/img/bg-1.png'); */
  position: absolute;
  top: 0;
  right: 75%;
  width: 60%;
  height: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

/* .bg-1-left{
  background-image: url('../public/assets/img/bg-1.png');
  background-repeat: no-repeat;
background-position: left -50% bottom 50%;
} */
.upperLogo img {
  width: 120px;
  object-fit: contain;
}